import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import {setI18n} from '@/libs/i18n'

import { sendPost, selectAccCdList, selectCommCodeList, selectComCdLists, sendPostFile, sendPostFileDown } from '@/libs/comm/transection';

import router from './router/index';
import VueCookies from "vue-cookies";

import '@/libs/sweet-alerts'
import '@/libs/sanitize'
import LoadScript from 'vue-plugin-load-script';
import VTooltip from 'v-tooltip'

import contContent from '@/views/contents/content/contContent'
import boardContent from '@/views/contents/content/boardContent'
import mainContent from '@/views/contents/mainContent'

import deptTable from '@/views/dept/deptTable'

import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'

Vue.component('cont-content',contContent)
Vue.component('board-content',boardContent)
Vue.component('main-content',mainContent) 

Vue.use(VueCookies);
Vue.use(LoadScript);
Vue.use(VTooltip);

Vue.component('deptTable',deptTable)

Vue.use(VueGtag, {
  config: {
      id: 'G-GM56Y3TS2T',  // Google Analytics의 Tracking ID
      params: {
        send_page_view: false,
      },
  }
});
Vue.use(VueMeta)

require('@/assets/scss/style.scss')

const getComponent = function (comp) {
  if(comp != null && comp != ''){
   return () => import(`@/views${comp}.vue`) ;
  }
  return null;
}

store.dispatch('setSiteContext', "sjna");

Vue.prototype.$comp = getComponent;
Vue.prototype.$tran = sendPost;
Vue.prototype.$tranFile = sendPostFile;
Vue.prototype.$tranFileDown = sendPostFileDown;
Vue.prototype.$codeList = selectCommCodeList;
Vue.prototype.$codeLists = selectComCdLists;
Vue.prototype.$accList = selectAccCdList;


Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue();

setI18n().then((i18n)=>{
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})